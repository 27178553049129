var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.isLoading,
          expression: "isLoading",
        },
      ],
      staticClass: "page",
    },
    [
      _c(
        "div",
        [
          _vm._m(0),
          _c("TimeslotCard", {
            attrs: {
              timeslot: _vm.timeslot,
              "queue-position": _vm.queuePosition,
              confirmed: true,
            },
          }),
          _c(
            "div",
            { staticClass: "flex" },
            [
              _c("MainButton", {
                staticClass: "confirm-button",
                attrs: {
                  title: "Показать меня в очереди",
                  type: "primary",
                  size: "big",
                  width: "full",
                },
                on: {
                  "button-clicked": function ($event) {
                    return _vm.$router.push({
                      name: "terminal-currentQueue",
                      params: { plate_truck: _vm.timeslot.num_auto },
                    })
                  },
                },
              }),
              _c("MainButton", {
                staticClass: "confirm-button",
                attrs: {
                  title: "Понятно",
                  type: "secondary",
                  size: "big",
                  width: "full",
                },
                on: {
                  "button-clicked": function ($event) {
                    return _vm.$router.push({ name: "terminal-carNumber" })
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("TechSupport"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "page__header" }, [
      _c("div", { staticClass: "page__header-title" }, [_vm._v("Статус")]),
      _c("div", { staticClass: "page__header-status" }, [
        _vm._v("На парковке"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }