<template>
  <section v-loading="isLoading" class="page">
    <div>
      <div class="page__header">
        <div class="page__header-title">Статус</div>
        <div class="page__header-status">На парковке</div>
      </div>

      <TimeslotCard
        :timeslot="timeslot"
        :queue-position="queuePosition"
        :confirmed="true"
      />

      <div class="flex">
        <MainButton
          title="Показать меня в очереди"
          type="primary"
          size="big"
          width="full"
          class="confirm-button"
          @button-clicked="
            $router.push({
              name: 'terminal-currentQueue',
              params: { plate_truck: timeslot.num_auto },
            })
          "
        />
        <MainButton
          title="Понятно"
          type="secondary"
          size="big"
          width="full"
          class="confirm-button"
          @button-clicked="$router.push({ name: 'terminal-carNumber' })"
        />
      </div>
    </div>

    <TechSupport />
  </section>
</template>

<script>
import { GET_PROFILE_FROM_STATE } from '@/views/profile/store/actions'
import { mapGetters } from 'vuex'
import Api from '@/api'
import MainButton from '@/views/terminalUI/components/UI/buttons/MainButton'
import TechSupport from '@/views/terminalUI/components/TechSupport'
import TimeslotCard from '@/views/terminalUI/components/TimeslotCard'
import finallyPagesMixin from '@/views/terminalUI/mixins/finallyPagesMixin'
export default {
  name: 'EarlyArrived',
  components: {
    MainButton,
    TechSupport,
    TimeslotCard,
  },
  mixins: [finallyPagesMixin],
  data() {
    return {
      isLoading: false,
      timeslot: null,
      queuePosition: 0,
    }
  },
  computed: {
    ...mapGetters({
      currentUser: GET_PROFILE_FROM_STATE,
    }),
    terminalIds() {
      return this.currentUser?.unloads?.map(item => item.id)
    },
  },
  async created() {
    this.isLoading = true
    this.timeslot = this.$route.params.timeslot
    const params = {
      unload_id: this.terminalIds,
      plate_truck: this.timeslot.plate_truck,
    }

    try {
      const { data } = await Api.terminal.getTerminalQueuePosition(params)

      this.queuePosition = data.position
    } catch (e) {
      console.log(e)
    } finally {
      this.isLoading = false
    }
  },
}
</script>

<style lang="sass" scoped>
.page
  display: flex
  flex-direction: column
  justify-content: space-between
  padding: 60px 11% 20px
  &__header
    display: flex
    justify-content: space-between
    &-title
      margin-bottom: 40px
      font-family: $fontPrimary
      font-size: 48px
      font-weight: 500
      line-height: 56px
    &-status
      padding: 0 43px
      height: 46px
      border-radius: 40px
      display: flex
      align-items: center
      justify-content: center
      font-weight: 500
      font-size: 20px
      background-color: $select
      color: $color-white

  .flex
    display: flex
    gap: 32px
  .confirm-button
    margin: 0 auto
</style>
